import "@/styles/globals.css";
import type {AppProps} from "next/app";
import {Catamaran} from "next/font/google";
import {Analytics} from "@vercel/analytics/react";
import {SpeedInsights} from "@vercel/speed-insights/react";

const font_catamaran = Catamaran({
    variable: '--font-catamaran',
    subsets: ['latin']
})

export default function App({Component, pageProps}: AppProps) {
    return (
        <main className={`${font_catamaran.variable} bg-[#232736]`}>
            <Component {...pageProps} />
            <Analytics />
            <SpeedInsights />
        </main>
    )
}
